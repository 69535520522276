import {SENTRY} from "../../../utils/constants";

const initSentry = (dsn) => (
    {
        type: SENTRY.INIT,
        payload: {
            dsn
        },
    }
)

export {initSentry};

