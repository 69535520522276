export {default} from './CompanyReducer';

/*
import { mapImageData } from "../../../utils/mappings";
import { COMPANY, IMAGE_TYPES } from "../../../utils/constants";

const STATE = {
  preview: false,
  source: "direct",
  loading: false,
  loaded: false,
  error: false,
  menu: [],
  all: [],
  config: {},
  mediaHubs: [],
};

const mapCompanyData = (payload) => {
  const { data } = payload;
  const companies = [];
  if (data && data.length > 0) {
    data.forEach((company) => {
      const {
        attributes,
        id,
        attributes: { title },
      } = company;
      const { media_hubs: remove, ...config } = attributes;
      const mediaHubs = mapMediaHubData(remove);
      companies.push({
        config: mapConfigData({ id, title, ...config }),
        mediaHubs,
      });
    });
    return companies;
  }
  return [];
};

const mapMenuData = (payload) => {
  const { data } = payload;
  const pre = [
    {
      key: "lobby",
      name: "Lobby",
      link: "/",
    },
    {
      key: "gls",
      name: "GLS Lobby",
      link: "/gls/lobby",
      subMenu: [
        {
          key: "gls-2024",
          name: "GLS 2024",
          // link: "/gls/lobby",
        },
        {
          key: "gls-dutube",
          name: "DUTUBE",
          link: "/gls/dutube",
        },
        {
          key: "gls-mediahub",
          name: "Media Hub",
          link: "/gls/mediahub",
        },
      ],
    },
  ];
  const items = data.map((item) => {
    const {
      attributes: {
        slug,
        navigation_title,
        media_hubs: { data: mediaHubData },
      },
    } = item;
    console.log(mediaHubData);
    const mediaHubItems = mediaHubData.map((mediaHub) => {
      const {
        id,
        attributes: { title, locked },
      } = mediaHub;
      return {
        key: `media-hub-${id}`,
        locked,
        name: `Media Hub ${title}`,
        link: `/${slug}/mediahub/${id}`,
      };
    });
    const subMenu = [
      ...mediaHubItems,
      {
        key: `${slug}-immpulse`,
        name: "IMMpulse",
        link: `/${slug}/news`,
      },
      {
        key: `${slug}-events`,
        name: "Events",
        link: `/${slug}/events`,
      },
    ];
    return {
      key: slug,
      name: navigation_title,
      link: `/${slug}`,
      subMenu,
    };
  });
  return [...pre, ...items];
};

const mapConfigData = (payload) => {
  const {
    mediaHubHeroImage,
    newsHeroImage,
    networkingHeroImage,
    newsBannerImage,
    horizontalLine,
    topLogo,
    lobbyImage,
  } = payload;
  return {
    ...payload,
    topLogo: mapImageData(topLogo, IMAGE_TYPES.FULL),
    mediaHubHeroImage: mapImageData(mediaHubHeroImage, IMAGE_TYPES.FULL),
    newsHeroImage: mapImageData(newsHeroImage, IMAGE_TYPES.FULL),
    networkingHeroImage: mapImageData(networkingHeroImage, IMAGE_TYPES.FULL),
    newsBannerImage: mapImageData(newsBannerImage, IMAGE_TYPES.FULL),
    horizontalLine: mapImageData(horizontalLine, IMAGE_TYPES.FULL),
    lobbyImage: mapImageData(lobbyImage, IMAGE_TYPES.FULL),
  };
};

const mapMediaHubData = (payload) => {
  const { data } = payload;
  return data.map((item) => {
    const {
      id,
      attributes: { title, locked, bannerImage },
    } = item;
    return {
      id: `${id}`,
      locked,
      title,
      bannerImage: mapImageData(bannerImage, IMAGE_TYPES.FULL),
    };
  });
};

const company = (state = STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case COMPANY.SET_QUERY_PARAMS:
      const { source, preview } = payload;
      if (state.preview === false && preview === true) {
        return { ...state, source, preview };
      }
      return { ...state, source };
    case COMPANY.LOAD:
      return {
        ...state,
        all: [],
        config: {},
        mediaHubs: [],
        menu: [],
        loading: true,
        loaded: false,
        error: false,
      };
    case COMPANY.LOAD_SUCCESS:
      const menu = mapMenuData(payload);
      const all = mapCompanyData(payload);
      if (all.length > 0) {
        const { config, mediaHubs } = [...all].shift();
        return {
          ...state,
          all,
          menu,
          loading: false,
          loaded: true,
          error: false,
          config,
          mediaHubs,
        };
      } else {
        return {
          ...state,
          all,
          menu,
          loading: false,
          loaded: true,
          error: true,
        };
      }
    case COMPANY.SELECT:
      const { companyName } = payload;
      const { all: companies } = state;
      const selected = companies.find(
        (company) => company.config.title === companyName
      );
      if (selected) {
        const { config, mediaHubs } = selected;
        return { ...state, config, mediaHubs };
      }
      return { ...state };
    case COMPANY.LOAD_ERROR:
      return {
        ...state,
        all: [],
        config: {},
        mediaHubs: [],
        menu: [],
        loaded: false,
        error: true,
      };
    case COMPANY.RESET:
      return { ...state, config: {} };
    default:
      return state;
  }
};
*/
